const en = {
  common: {
    or: 'or',
    cancel: 'Cancel',
    close: 'Close',
    reset: 'Reset',
    save: 'Save',
    submit: 'Submit',
    next: 'Next',
    previous: 'Previous',
    enable: 'Enable',
    disable: 'Disable',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    revise: 'Revise',
    release: 'Release',
    reject: 'Reject',
    process: 'Process',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    requestRelease: 'Request Release',
    actions: 'Actions',
    finish: 'Finish',
    changePassword: 'Change password',
    updatePassword: 'Update password',
    password: 'Password',
    approve: 'Approve',
    propose: 'Propose',
    discardFormConfirm: 'Are you sure? Any unsaved data will be lost.',
    openMitarbeiter: 'Open Employee',
    download: 'Download',
    preview: 'Preview',
    status: 'Status',
    send: 'Send',
  },

  app: {
    title: 'Personal',
  },

  entities: {
    template: {
      name: 'Template',
      label: 'Templates',
      menu: 'Templates',
      list: {
        menu: 'Templates',
        title: 'Templates',
      },
      create: {
        success: 'Template saved successfully',
      },
      update: {
        success: 'Template updated successfully',
      },
      destroy: {
        success: 'Template deleted successfully',
      },
      destroyAll: {
        success: 'Template(s) deleted successfully',
      },
      edit: {
        title: 'Edit Template',
      },
      fields: {
        id: 'Id',
        template: 'Template',
        type: 'Template Type',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        type: {
          bestaetigung: 'Bestätigung',
          kuendigung: 'Kündigung',
          vertrag: 'Vertrag',
          karenz: 'Karenz',
        },
      },
      new: {
        title: 'New Template',
      },
      view: {
        title: 'View Template',
      },
    },

    bestaetigung: {
      name: 'Bestaetigung',
      label: 'Bestätigungen',
      menu: 'Bestätigungen',
      list: {
        menu: 'Bestätigungen',
        title: 'Bestätigungen',
      },
      create: {
        success: 'Bestätigung saved successfully',
      },
      update: {
        success: 'Bestätigung updated successfully',
      },
      destroy: {
        success: 'Bestätigung deleted successfully',
      },
      destroyAll: {
        success: 'Bestätigungen deleted successfully',
      },
      edit: {
        title: 'Edit Bestätigung',
      },
      fields: {
        id: 'Id',
        file: 'File',
        bestaetigungType: 'Bestätigung Type',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        bestaetigungType: {
          mitGehalt: 'mitGehalt',
          mitGehaltEnglish: 'mitGehaltEnglish',
          inMutterkarenz: 'inMutterkarenz',
          warBeschaeftigt: 'warBeschaeftigt',
          istBeschaeftigt: 'istBeschaeftigt',
        },
      },
      new: {
        title: 'New Bestätigung',
      },
      view: {
        title: 'View Bestätigung',
      },
    },

    mitarbeiter: {
      name: 'Employee',
      label: 'Employee',
      menu: 'Employee',
      list: {
        menu: 'Employee',
        title: 'Employee',
      },
      create: {
        success: 'Employee saved successfully',
      },
      update: {
        success: 'Employee updated successfully',
      },
      destroy: {
        success: 'Employee deleted successfully',
      },
      destroyAll: {
        success: 'Employee deleted successfully',
      },
      edit: {
        title: 'Edit Employee',
      },
      createNotiz: {
        success: 'Notiz saved successfully',
      },
      bestaetigungDownload: {
        success: 'Confirmation downloaded successfully',
      },
      fields: {
        id: 'Id',
        mitarbeiterNr: 'MitarbeiterNr',
        anrede: 'Anrede',
        titel: 'Titel',
        nachname: 'Nachname',
        vorname: 'Vorname',
        vollname: 'Name',
        email: 'Email',
        fakultaet: 'Fakuläet',
        strasse: 'Adresse',
        plz: 'PLZ',
        stadt: 'Stadt',
        svNummer: 'SV-Nummer',
        geburtsdatum: 'Geburts Datum',
        telefon: 'Telefon',
        urlaubstage: 'Vacation days',
        arbeitstage: 'Working days',
        sa_ausb: 'AUSB',
        sa_fkt: 'FKT',
        sa_taet1: 'TAET1',
        sa_taet2: 'TAET2',
        sa_verw: 'VERW',
        iban: 'IBAN',
        bic: 'BIC',
        persoenlicheData: 'Persönliche Daten',
        mitteilung: 'Mitteilungen',
        vertrag: 'Verträge',
        karenz: 'Karenzen',
        krankmeldung: 'Krankmeldung',
        gesamtbrutto: 'Brutto',
        gesamtwochenstunden: 'WoStd',
        mandant: 'Mandant',
        verwendung: 'Verwendung',
        probeMonat: 'Probemonat',
        vertragsart: 'Vertragsart',
        eintrittsdatum: 'Begin',
        austrittsdatum: 'Ende',
        gehaltProJahr: 'Gehalt pro Jahr',
        karenzvertretungMitarbeiter: 'Karenzvertretung',
        kostenstelle: 'Kostenstelle',
        prozent: 'Prozent',
        von: 'Von',
        bis: 'Bis',
        wochenstunden: 'Wochenstunden',
        brutto: 'Brutto',
        verwendungKostenstelle: 'Tätigkeit',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        anrede: {
          herr: 'Herr',
          frau: 'Frau',
        },
      },
      new: {
        title: 'New Employee',
      },
      view: {
        title: 'View Employee',
      },
    },

    vertrag: {
      name: 'Contract',
      label: 'Contract',
      menu: 'Contract',
      list: {
        menu: 'Contract',
        title: 'Contract',
      },
      create: {
        success: 'Contract saved successfully',
      },
      update: {
        success: 'Contract updated successfully',
      },
      destroy: {
        success: 'Contract deleted successfully',
      },
      destroyAll: {
        success: 'Contract deleted successfully',
      },
      sendEmail: {
        success: 'Contract sent successfully to mitarbeiter',
      },
      edit: {
        title: 'Edit Contract',
      },
      vertragsentwurf: {
        success: 'Contract draft created successfully',
      },
      fields: {
        id: 'Id',
        sentAt: 'Sent to Employee',
        decisionDate: 'Decision date',
        vertragUploadDate: 'Contract draft upload date',
        vertragsentwurf: 'Contract draft',
        vertrag: 'Contract',
        entwurfStatus: 'Draft status',
        vertragStatus: 'Contract status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        entwurfStatus: {
          releasePending: 'Release pending',
          active: 'Active',
          inactive: 'Inactive',
          rejected: 'Rejected',
          revisionRequested: 'Revision requested',
        },
        vertragStatus: {
          releasePending: 'Release pending',
          active: 'Active',
          inactive: 'Inactive',
          rejected: 'Rejected',
          revisionRequested: 'Revision requested',
        },
      },
      new: {
        title: 'New Contract',
      },
      view: {
        title: 'View Contract',
      },
    },
    approvalRequestModel: {
      list: {
        menu: 'Vertragsentwurf Requests',
        title: 'Vertragsentwurf Requests',
      },
      fields: {
        id: 'Id',
        approver: 'Approver',
        status: 'Status',
        comment: 'Comment',
        updatedAt: 'Updated at',
      },
      enumerators: {
        status: {
          requested: 'Requested',
          approved: 'Approved',
          rejected: 'Rejected',
          revisionRequested: 'Requested revision',
        },
      },
    },

    beendigung: {
      name: 'Termination',
      label: 'Termination',
      menu: 'Termination',
      list: {
        menu: 'Termination',
        title: 'Termination',
      },
      create: {
        success: 'Termination saved successfully',
      },
      update: {
        success: 'Termination updated successfully',
      },
      destroy: {
        success: 'Termination deleted successfully',
      },
      destroyAll: {
        success: 'Termination deleted successfully',
      },
      sendEmail: {
        success: 'Termination sent successfully to mitarbeiter',
      },
      edit: {
        title: 'Edit Termination',
      },
      fields: {
        id: 'Id',
        sentAt: 'Sent to Employee',
        decisionDate: 'Decision date',
        entwurf: 'Entwurf',
        status: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        status: {
          releasePending: 'Release pending',
          active: 'Active',
          inactive: 'Inactive',
          rejected: 'Rejected',
          revisionRequested: 'Revision requested',
        },
      },
      new: {
        title: 'New Termination',
      },
      view: {
        title: 'View Termination',
      },
    },

    mandant: {
      name: 'Mandant',
      label: 'Mandant',
      menu: 'Mandant',
      list: {
        menu: 'Mandant',
        title: 'Mandant',
      },
      create: {
        success: 'Mandant saved successfully',
      },
      update: {
        success: 'Mandant updated successfully',
      },
      destroy: {
        success: 'Mandant deleted successfully',
      },
      destroyAll: {
        success: 'Mandant deleted successfully',
      },
      edit: {
        title: 'Edit Mandant',
      },
      fields: {
        id: 'Id',
        nummer: 'Number',
        name: 'Name',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Mandant',
      },
      view: {
        title: 'View Mandant',
      },
    },

    vertragsart: {
      name: 'Vertragsart',
      label: 'Vertragsart',
      menu: 'Vertragsart',
      list: {
        menu: 'Vertragsart',
        title: 'Vertragsart',
      },
      create: {
        success: 'Vertragsart saved successfully',
      },
      update: {
        success: 'Vertragsart updated successfully',
      },
      destroy: {
        success: 'Vertragsart deleted successfully',
      },
      destroyAll: {
        success: 'Vertragsart deleted successfully',
      },
      edit: {
        title: 'Edit Vertragsart',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Vertragsart',
      },
      view: {
        title: 'View Vertragsart',
      },
    },

    fakultaet: {
      name: 'Fakultät',
      label: 'Fakultät',
      menu: 'Fakultät',
      list: {
        menu: 'Fakultät',
        title: 'Fakultät',
      },
      create: {
        success: 'Fakultät saved successfully',
      },
      update: {
        success: 'Fakultät updated successfully',
      },
      destroy: {
        success: 'Fakultät deleted successfully',
      },
      destroyAll: {
        success: 'Fakultät deleted successfully',
      },
      edit: {
        title: 'Edit Fakultät',
      },
      fields: {
        id: 'Id',
        name: 'Name',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Fakultät',
      },
      view: {
        title: 'View Fakultät',
      },
    },

    verwendungKostenstelle: {
      name: 'Tätigkeit',
      label: 'Tätigkeit',
      menu: 'Tätigkeit',
      list: {
        menu: 'Tätigkeit',
        title: 'Tätigkeit',
      },
      create: {
        success: 'Tätigkeit saved successfully',
      },
      update: {
        success: 'Tätigkeit updated successfully',
      },
      destroy: {
        success: 'Tätigkeit deleted successfully',
      },
      destroyAll: {
        success: 'Tätigkeit deleted successfully',
      },
      edit: {
        title: 'Edit Tätigkeit',
      },
      fields: {
        id: 'Id',
        nummer: 'Number',
        name: 'Name',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Tätigkeit',
      },
      view: {
        title: 'View Tätigkeit',
      },
    },

    kostentraeger: {
      name: 'Kostenträger',
      label: 'Kostenträger',
      menu: 'Kostenträger',
      list: {
        menu: 'Kostenträger',
        title: 'Kostenträger',
      },
      create: {
        success: 'Kostenträger saved successfully',
      },
      update: {
        success: 'Kostenträger updated successfully',
      },
      destroy: {
        success: 'Kostenträger deleted successfully',
      },
      destroyAll: {
        success: 'Kostenträger deleted successfully',
      },
      edit: {
        title: 'Edit Kostenträger',
      },
      fields: {
        id: 'Id',
        nummer: 'Number',
        name: 'Name',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Kostenträger',
      },
      view: {
        title: 'View Kostenträger',
      },
    },

    kostenstelle: {
      name: 'Kostenstelle',
      label: 'Kostenstelle',
      menu: 'Kostenstelle',
      list: {
        menu: 'Kostenstelle',
        title: 'Kostenstelle',
      },
      create: {
        success: 'Kostenstelle saved successfully',
      },
      update: {
        success: 'Kostenstelle updated successfully',
      },
      destroy: {
        success: 'Kostenstelle deleted successfully',
      },
      destroyAll: {
        success: 'Kostenstelle deleted successfully',
      },
      edit: {
        title: 'Edit Kostenstelle',
      },
      fields: {
        id: 'Id',
        nummer: 'Number',
        name: 'Name',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Kostenstelle',
      },
      view: {
        title: 'View Kostenstelle',
      },
    },

    anstellung: {
      name: 'Anstellung',
      label: 'Anstellung',
      menu: 'Anstellung',
      list: {
        menu: 'Anstellung',
        title: 'Anstellung',
      },
      create: {
        success: 'Anstellung saved successfully',
      },
      update: {
        success: 'Anstellung updated successfully',
      },
      destroy: {
        success: 'Anstellung deleted successfully',
      },
      destroyAll: {
        success: 'Anstellung deleted successfully',
      },
      edit: {
        title: 'Edit Anstellung',
      },
      fields: {
        id: 'Id',
        verwendung: 'Verwendung',
        mandant: 'Mandant',
        probeMonat: 'Probemonat',
        vertragsart: 'Vertragsart',
        eintrittsdatum: 'Begin',
        austrittsdatum: 'Ende',
        gehaltProJahr: 'Gehalt pro Jahr',
        gesamtbrutto: 'Gesamtbrutto',
        gesamtwochenstunden: 'GesamtWoStd',
        change: 'Change',
        mitarbeiter: 'Employee',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        probeMonat: { ja: 'Ja', nein: 'Nein' },
        gehaltProJahr: { 12: '12', 14: '14' },
      },
      new: {
        title: 'New Anstellung',
      },
      view: {
        title: 'View Anstellung',
      },
    },

    grundgehalt: {
      name: 'Grundgehalt',
      label: 'Grundgehalt',
      fields: {
        id: 'Id',
        kostenstelle: 'Kostenstelle',
        prozent: 'Prozent',
        von: 'Von',
        bis: 'Bis',
        wochenstunden: 'Wochenstunden',
        brutto: 'Brutto',
        verwendungKostenstelle: 'Tätigkeit',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
    },

    knowledgeBase: {
      name: 'knowledgeBase',
      label: 'Knowledge Base',
      menu: 'Knowledge Base',
      list: {
        menu: 'Knowledge Base',
        title: 'Knowledge Base',
      },
      create: {
        success: 'Issue saved successfully',
      },
      update: {
        success: 'Issue updated successfully',
      },
      destroy: {
        success: 'Issue deleted successfully',
      },
      destroyAll: {
        success: 'Issue deleted successfully',
      },
      edit: {
        title: 'Edit Issue',
      },
      fields: {
        id: 'Id',
        issue: 'Issue',
        resolution: 'Resolution',
        tag: 'Tag',
        document: 'Document',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {},
      new: {
        title: 'New Issue',
      },
      view: {
        title: 'View Issue',
      },
    },

    calendar: {
      name: 'Calendar',
      label: 'Calendar',
      menu: 'Calendar',
      list: {
        menu: 'Calendar',
        title: 'Calendar',
      },
      create: {
        success: 'Calendar item created successfully',
      },
      update: {
        success: 'Calendar item updated successfully',
      },
      destroy: {
        success: 'Calendar item deleted successfully',
      },
      destroyAll: {
        success: 'Calendar entries deleted successfully',
      },
      edit: {
        title: 'Edit item',
      },
      fields: {
        id: 'Id',
        title: 'Title',
        startDate: 'Start date',
        endDate: 'End date',
        url: 'URL',

        finished: 'Finished',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
      options: {
        displayPeriod: 'Zeitraum',
      },
      new: {
        title: 'New entry',
      },
      view: {
        title: 'View item',
      },
    },
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully',
    },
    createAnAccount: 'Request account registration',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    requestRegistration: 'Request registration',
    signout: 'Sign out',
    backToSignin: 'Back to Sign in',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the admin to grant you privileges.`,
    },
    passwordResetRequest: {
      message: 'Request password reset',
      error: `Email not recognized`,
    },
    passwordReset: {
      message: 'Reset password',
    },
    passwordChange: {
      title: 'Change Password',
      success: 'Password successfully changed',
      mustMatch: 'Passwords must match',
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetRequestSuccess: `Password reset requested successfully`,
    passwordResetSuccess: `Password changed successfully`,
    //TODO
    //Text to be specified from SFU
    registrationSuccess:
      'Registration requested successful! You will receive email with details once your registration is processed by the Administrators.',
  },

  passwordChange: {
    title: 'Change password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    containsEightCharacters: '8 Characters',
    containsNumber: 'Contains Number',
    containsUppercase: 'Contains Uppercase',
    containsSpecialCharacter: 'Contains Special Character',
    passwordUpdateSuccess: 'Password successfully updated',
    passwordUpdateFailed: 'Password update failed',
  },

  roles: {
    //TODO
    //Define description for the user roles
    itAdmin: {
      label: 'IT Administrator',
      description: 'Full access to all resources',
    },
    personalAdmin: {
      label: 'Personal Administrator',
      description: 'Full access to all resources',
    },
    personal: {
      label: 'Personal',
      description: 'Restricted access to Personal operations',
    },
  },

  user: {
    title: 'Identity and Access Management',
    menu: 'Users',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doSignupSuccess: 'User registration requested successfully',
    doUpdateSuccess: 'User saved successfully',
    doDestroySuccess: 'User successfully deleted',
    doDestroyAllSuccess: 'Users successfully deleted',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint:
        'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    approve: {
      title: 'Approve user',
      approve: 'Approve',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    fields: {
      id: 'Id',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      anrede: 'Anrede',
      academicTitle: 'Academic Title',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      newPasswordConfirmation: 'New Password Confirmation',
      rememberMe: 'Remember me',
    },
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
      form: {
        notAllFieldsFilled: 'Nicht alle Formularfelder sind ausgefüllt.',
      },
    },
    status: {
      enabled: 'Enabled',
      disabled: 'Disabled',
    },
    anrede: {
      frau: 'Frau',
      herr: 'Herr',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      /* success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.', */
      success: 'Settings saved successfully.',
    },
    fields: {
      contractApprover: 'Contract Approvers',
      beendigungApprover: 'Termination Approvers',
      personalDepartmentMailbox: 'Personal Mailbox',
    },
  },
  home: {
    menu: 'Home',
    message: `In case of questions please contact research.admin@sfu.ac.at.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
    },
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    500: 'Sorry, the server is reporting an error',
    429: 'Too many requests. Please try again later.',
    forbidden: {
      message: 'Forbidden',
    },
    validation: {
      message: 'An error occurred',
    },
    defaultErrorMessage: 'Ops, an error occurred',
    defaultWarningMessage: 'Ops, a warning occurred',
    projectNavigateStep:
      'You are not allowed to navigate to project steps in advanced',
    files: {
      noPermissions: 'You are not allowed to {0} file.',
    },
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },
};

export default en;
